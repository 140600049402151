import React, { useEffect, useRef, useState } from "react";

const Loader = () => {
  const vidRef = useRef();
  const [mute, setMuted] = useState(false);
  useEffect(() => {
    const promise = vidRef.current.play();
    if (promise !== undefined) {
      promise
        .then(() => {
          // Autoplay started
        })
        .catch((error) => {
          // Autoplay was prevented.
          vidRef.current.muted = true;
          vidRef.current.play();
        });
    }
  }, []);

  const handleToggleMute = () => setMuted(!mute);
  return (
    <div className="loader-vid">
      <video
        src="videos/loaderVideo.mp4"
        ref={vidRef}
        muted={mute}
        autoPlay
        loop
        playsInline
      />
      <button className="audio-btn" onClick={() => handleToggleMute()}>
        Audio{" "}
        {mute ? (
          <i class="fa-solid fa-volume-xmark"></i>
        ) : (
          <i class="fa-solid fa-volume-high"></i>
        )}
      </button>
    </div>
  );
};

export default Loader;
