import gsap from "gsap";
import React, { useEffect } from 'react';
import Header from './header';

const CarDetail = () => {
    const userData = localStorage.getItem("myState");
    const carDetails = JSON.parse(userData);
    // alert(JSON.stringify(carDetails?.carDetails))
    useEffect(() => {
        let tl5 = gsap.timeline({
            scrollTrigger: {
                scrub: 3,
                trigger: ".inner-wrap-3",
                pin: ".main-3"
            },
            defaults: { duration: 1 }
        })
        tl5.from(".car-feature-card", {
            yPercent: 45,
            alpha: 0,
            ease: "power1.out",
        })
        tl5.to(".car-feature-card", {
            y: 0,
            alpha: 1,
            ease: "power1.out"
        }, 1)
        tl5.from(".detail-card", {
            yPercent: 120,
            alpha: 0,
            ease: "power1.out"
        })
        tl5.to(".detail-card", {
            y: 0,
            alpha: 1,
            ease: "power1.out"
        }, 2)
        tl5.from(".policy-card", {
            yPercent: -150,
            alpha: 0,
            ease: "power1.out"
        })
        tl5.to(".policy-card", {
            y: 0,
            alpha: 1,
            ease: "power1.out"
        }, 3);
    }, []);


    return (
        <div>
            <Header />
            <div className="main-3">
                <div className="inner-wrap-3">
                    <section className="detail-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="detail-blk">
                                        <div className="detail-inner-blk">

                                            <div className="detail-bar">
                                                <div className="detail-inner-bar">
                                                    <div className="detail-inner">
                                                        <h1>feature</h1>
                                                        <ul className="car-features-blk">
                                                            <li>
                                                                <div className="car-feature-list">
                                                                    <span className="feature-list-title">Airbags</span>
                                                                    <span className="feature-list-icon"><img src="images/airbags.svg" alt="Airbag" /></span>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="car-feature-list">
                                                                    <span className="feature-list-title">Sensor</span>
                                                                    <span className="feature-list-icon"><img src="images/sensor.svg" alt="Sensor" /></span>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="car-feature-list">
                                                                    <span className="feature-list-title">Steering Wheel</span>
                                                                    <span className="feature-list-icon"><img src="images/steering.svg" alt="Steering Wheel" /></span>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="car-feature-list">
                                                                    <span className="feature-list-title">Power Windows</span>
                                                                    <span className="feature-list-icon"><img src="images/windows.svg" alt="Power Windows" /></span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <h2 className="rent-per-day">{carDetails.carRent}</h2>
                                                </div>
                                            </div>



                                            <div className="car-detail-card car-feature-card" >
                                                <div className="car-detail-header-blk">
                                                    <div className="car-detail-heading">
                                                        <h2>{carDetails.carName}</h2>
                                                    </div>
                                                    <div className="car-detail-title">
                                                        <span>Featured</span>
                                                    </div>
                                                </div>
                                                <div className="car-detail-content">
                                                    {carDetails?.carDetails.map((data, i) => (
                                                        <div className="car-detail-row">
                                                            <span className="detail-heading">{data.name}</span>
                                                            <span className="detail-value">{data.text}</span>
                                                        </div>
                                                    ))}
                                                    <div className="car-detail-row">
                                                        <span className="detail-heading">Type</span>
                                                        <span className="detail-value">{carDetails.type}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="car-detail-card detail-card" >
                                                <div className="car-detail-header-blk">
                                                    <div className="car-detail-heading">
                                                        <h2>DETAILS</h2>
                                                    </div>
                                                </div>
                                                <div className="car-detail-content">
                                                    <div className="car-detail-row">
                                                        <span className="detail-heading">{carDetails?.details}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="car-detail-card policy-card" >
                                                <div className="car-detail-header-blk">
                                                    <div className="car-detail-heading">
                                                        <h2>POLICY</h2>
                                                    </div>
                                                    <div className="car-detail-title">
                                                        <span>Cancellation</span>
                                                        <span className="button-blk">
                                                            <button className="enable-btn">enable</button>
                                                        </span>
                                                        <span>before 7 day(s)</span>
                                                    </div>
                                                </div>
                                                <div className="car-detail-content">
                                                    <div className="car-detail-row">
                                                        <span className="detail-heading">The company continued to work at its regular pace. In 1972, the P250 Urraco, the 400 GT Jarama, the 400 GT Espada and the P400 Miura SV were in full production. That year, in an attempt to improve sales that were frankly quite disappointing until then, the Jarama hand a 365-hp engine and was dubbed the Jarama S.</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="car-bg">
                            <img src={carDetails?.carImage} alt="Cars" />
                        </div>
                    </section>

                    <div className="section-nav">
                        <div className="section-nav-inner section-nav-inner-wrapper">
                            <div className="subnav-items">
                                <a className="subnav-title">Scroll-Up</a>
                                <a className="subnav-title">Car Details</a>
                                <a className="subnav-title">Scroll-down</a>
                            </div>
                        </div>

                        <div className="section-nav-arrow-wrapper">
                            <div className="section-nav-arrow-prev">
                                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.700562 6L0.00061029 5.29999L5.3006 4.6334e-07L10.6006 5.29999L9.90057 6L5.3006 1.40002L0.700562 6Z" fill="white" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.700562 11L0.00061029 10.3L5.3006 5L10.6006 10.3L9.90057 11L5.3006 6.40002L0.700562 11Z" fill="white" />
                                </svg>
                            </div>
                            <div className="section-nav-arrow-next">
                                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.90002 5L10.6 5.70001L5.29999 11L-1.22394e-07 5.70001L0.700012 5L5.29999 9.59998L9.90002 5Z" fill="white" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.90002 1.73098e-06L10.6 0.700014L5.29999 6L-1.22394e-07 0.700012L0.700012 1.22394e-07L5.29999 4.59998L9.90002 1.73098e-06Z" fill="white" />
                                </svg>
                            </div>
                        </div>

                        <div className="section-nav-line">
                            <div className="section-nav-line-selector"></div>
                        </div>
                    </div>

                </div>
                {/* <Footer /> */}
            </div>
        </div>
    )
}

export default CarDetail;