import React, { useEffect } from "react";
import Header from "./header";
import Footer from "./footer";

function VideoShoots() {
  useEffect(() => {
    // Disable scroll restoration when the component mounts
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    // Re-enable scroll restoration when the component unmounts
    return () => {
      if ("scrollRestoration" in window.history) {
        window.history.scrollRestoration = "auto";
      }
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header />
      <div className="service-main">
        <div class="mainwrap">
          <section
            className="services-hero"
            style={{ position: "relative", overflow: "hidden" }}>
            <div className="bg2"><video
              autoPlay
              muted
              loop
              playsInline
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                zIndex: "-1",
                objectFit: "cover", // This ensures video covers the section
                background: "rgb(0 0 0 / 45%)", // Keeping the dark overlay
              }}>
              <source src="/videos/video-shoot.mp4" type="video/mp4" />
            </video></div>

            <div className="service-hero-inner-blk">
              <h2>Video Shoot Rentals</h2>
            </div>
            <div className="effect">
              <h2 className="splittext">SCROLL TO EXPLORE</h2>
            </div>
            <div className="scroll-down">
              <span className="rectline" />
              <span className="rectblk" />
            </div>
          </section>

          <section className="service-content-section about-content-section">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <div className="content-blk">
                    <h2>Elevating Your Driving Experience Since 2010</h2>
                    <p>
                      Emerging from the vibrant heart of South Hackensack, NJ,
                      Broadway Supercars stands as a luminous symbol of luxury,
                      high performance, and unparalleled driving adventures. For
                      more than ten years, we have transcended the typical
                      definition of a car rental service; we've evolved into a
                      living ethos of passion, excellence, and the chase for the
                      exceptional. Our meticulously selected range of exotic
                      cars is more than just metal and wheels — they embody
                      dreams waiting to be lived.
                    </p>

                    <p>
                      Our humble beginnings in a garage on Broadway in Passaic,
                      NJ have flourished into becoming one of the nation's most
                      esteemed exotic car enterprises. Through changing
                      presidential tenures, shifting global dynamics, and the
                      ebbs and flows of time, the saga of the supercar endures
                      with us.
                    </p>

                    <p>
                      Every car in our collection, sourced from the globe's most
                      legendary automotive brands, represents not just
                      engineering, but a narrative of status, might, and zeal.
                      Step into a realm where engines' roars stir souls, where
                      every design detail speaks of unmatched opulence, and
                      where each drive isn’t merely about transportation — it’s
                      about ruling the tarmac.
                    </p>

                    <p>
                      Our guiding principle has always been unwavering: viewing
                      every customer as a cherished part of our legacy. This
                      ethos is timeless in our eyes.
                    </p>

                    <a href="/#/contectus" className="section-btn">
                      Reach Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="common-card-section">
            <div className="container">
              <div className="row justify-content-center mb-3 mb-md-5">
                <div className="col-lg-6">
                  <div className="common-section-heading">
                    <h2 className="text-center mb-3">
                      The Broadway Distinction: Excellence in Every Drive
                    </h2>
                    <p className="text-center">
                      Unparalleled service, cutting-edge innovation, and loyalty
                      converge to redefine the exotic car experience.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="common-flex-wrapper">
                    <div className="common-inner-wrapper">
                      <div className="common-img-wrapper">
                        <img src="images/bentley.png" alt="Cars" />
                      </div>
                      <div className="common-card-heading">
                        <h2>Unparalleled Customer Service</h2>
                      </div>
                      <div className="common-section-text">
                        <p>
                          At Broadway Super Cars, we believe that an elite
                          driving experience begins long before you grip the
                          steering wheel. It starts with our unwavering
                          dedication to delivering impeccable customer service.
                          From the moment you interact with our team, you'll be
                          treated with the kind of care, respect, and attention
                          that’s typically reserved for VIPs. Our team members
                          aren't just trained; they're steeped in a culture of
                          excellence, ensuring every inquiry, request, and
                          concern is addressed promptly and professionally. It's
                          this commitment to our clientele that sets us apart,
                          making us not just a choice, but the definitive choice
                          for discerning drivers.
                        </p>
                      </div>
                      <a
                        href="javascript:void(0)"
                        className="common-card-link"
                      />
                    </div>
                    <div className="common-inner-wrapper">
                      <div className="common-img-wrapper">
                        <img src="images/lamborghini-2.png" alt="Cars" />
                      </div>
                      <div className="common-card-heading">
                        <h2>Cutting-edge Technology and Supply Chain</h2>
                      </div>
                      <div className="common-section-text">
                        <p>
                          Being at the forefront of the exotic car rental
                          industry requires more than just passion; it demands a
                          robust foundation powered by technology. Our advanced
                          technological infrastructure, coupled with an
                          optimized supply chain, ensures that our fleet remains
                          contemporary, vibrant, and in line with the latest
                          automotive marvels. Not only does this allow us to
                          consistently refresh our lineup with modern vehicles,
                          but it also empowers us to offer competitive pricing
                          that defies industry standards. While others chase
                          trends, we set them, staying ahead of the curve and
                          guaranteeing our customers access to the latest and
                          the best, without breaking the bank.
                        </p>
                      </div>
                      <a
                        href="javascript:void(0)"
                        className="common-card-link"
                      />
                    </div>
                    <div className="common-inner-wrapper">
                      <div className="common-img-wrapper">
                        <img src="images/lamborghini-3.png" alt="Cars" />
                      </div>
                      <div className="common-card-heading">
                        <h2>Innovative Membership with Rewards</h2>
                      </div>
                      <div className="common-section-text">
                        <p>
                          Loyalty, in our eyes, is a two-way street. We deeply
                          value the trust our customers place in us and are
                          always seeking ways to show our appreciation. Enter
                          our innovative membership program, meticulously
                          designed to celebrate and reward our patrons. By
                          choosing Broadway Super Cars repeatedly, members don't
                          just receive unparalleled driving experiences; they
                          unlock a trove of rewards and exclusive benefits. From
                          special discounts to early access to our latest fleet
                          additions, our membership is our ode to those who've
                          made our journey extraordinary. It's not just about
                          loyalty; it's about becoming a part of a legacy, where
                          every drive is matched with a token of our gratitude.
                        </p>
                      </div>
                      <a
                        href="javascript:void(0)"
                        className="common-card-link"
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="col-12 mt-4">
		<a href="#" className="section-btn">Discover more</a>
	  </div> */}
              </div>
            </div>
          </section>
          <section className="service-slider-section">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <div className="content-blk">
                    <h2>Luxury by Design </h2>
                    <h3>Precision in Every Endeavor</h3>
                    <br></br>
                    <p>
                      Luxury is more than just opulence; it's a carefully
                      crafted experience. Every detail, from our curated fleet
                      of exotic vehicles to our exceptional service, has been
                      meticulously planned to offer unparalleled excellence. Our
                      showroom's strategic location, a stone's throw away from
                      major and private airports, and encircled by four pivotal
                      highways accessible within minutes, underscores this
                      commitment.{" "}
                    </p>
                    <p>
                      This perfect marriage of luxury and efficiency ensures
                      that every journey, every touchpoint, and every moment
                      spent with us reflects an exceptional standard of elegance
                      and precision.
                    </p>
                    <a
                      href="https://www.google.com/maps/dir//broadway+super+cars/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x89c2fed80724313f:0xfefc8a8813928047?sa=X&ved=2ahUKEwj6hMzd8t2BAxU_FlkFHTSnCtIQ9Rd6BAg3EAA&ved=2ahUKEwj6hMzd8t2BAxU_FlkFHTSnCtIQ9Rd6BAhBEAU"
                      className="section-btn">
                      Directions
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="slider-wrapper">
              <div className="container-fluid g-0">
                <div className="row gx-0">
                  <div className="col-lg-12">
                    <div className="slider-blk">
                      <div className="img-slide">
                        <img
                          src="https://images.pexels.com/photos/248370/pexels-photo-248370.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                          alt="Car"
                        />
                      </div>
                      <div className="car-text-block">
                        <h3 style={{ textAlign: "center" }}>
                          An Ethos of Exclusivity
                        </h3>
                        <p style={{ textAlign: "center" }}>
                          At Broadway Supercars, our legacy extends beyond the
                          impeccable sheen of our vehicles and the roar of their
                          engines. It's found in the distinguished circle of
                          members who recognize and resonate with unparalleled
                          grandeur. When you choose to align with us, you don't
                          merely access a fleet; you enter a realm of
                          exclusivity. A realm where rarity meets reverence,
                          where distinction is a given, and where every member
                          becomes a testament to the elite. Embracing Broadway
                          Supercars isn’t just a choice; it's an affirmation of
                          your place among the select few who value the
                          quintessence of luxury and performance. In this realm,
                          you're not just elite; you're Broadway Supercars
                          elite.
                        </p>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                          className="slider-button-blk">
                          <a
                            href="https://buy.stripe.com/8wMcMZ3Pr9ZC9GMeUU"
                            style={{ textAlign: "center" }}
                            className="slider-btn">
                            Membership
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        
        </div>
       <Footer/>
      </div>
    </div>
  );
}

export default VideoShoots;
