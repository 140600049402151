import React from "react";
import GoogleMapReact from "google-map-react";
const Marker = ({ icon }) => <div>{icon} </div>;
export default function MapComponent() {
  // map method
  const defaultProps = {
    center: {
      lat:40.87196835553787,
      lng:-74.05666653795024
    },
    zoom: 11,
  };

  return (
    <div style={{ height: "100%", width: "100%", cursor: "none" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAIcJXGmfpzfr1bSVhewjpMx17SdreO-4g" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}>
        <Marker
          lat={40.87196835553787}
          lng={  -74.05666653795024}
          icon={
            <img
              src="images/locationIcon.png"
              alt="map"
              height="50px"
              width="50px"
            />
          }
        />
      </GoogleMapReact>
    </div>
  );
}


