import * as Yup from "yup";

const initialValues = {
  email: "",
  title: "",
  mobileNumber: "",
  message: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address")
    .email("Invalid email address")
    .required("User email is required*"),
  title: Yup.string().required("Enter title*"),
  mobileNumber:   Yup.string().matches(/^[0-9]+$/, "Mobile number must contain only numbers")
  .required("Enter mobile number*"),
  message: Yup.string().required("Enter message*"),
});

export { initialValues, validationSchema };
