/* eslint-disable eqeqeq */
import gsap from "gsap";
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import Header from "./header";
import { MouseContext } from "./custom_mouse/context/mouse-context";
import { useNavigate, useParams } from "react-router-dom";
import vehicleCategory from ".././services/JSON/vehicleCategory.json";
import { ScrollTrigger } from "gsap/all";

import CarCollections from "./carCollections";


// import features from "../services/JSON/vehicleCategory.json";
gsap.registerPlugin(ScrollTrigger);

const OurCollection = () => {
  const { cursorChangeHandler } = useContext(MouseContext);
  const [selectedOption, setSelectedOption] = useState("");
  const component = useRef();
  const suvComponent = useRef();
  const convertibleComponent = useRef();
  const slider = useRef();
  const suvSliders = useRef();
  const convertableSlider = useRef();
  const collectionComponent = useRef();
  const collectionSlider = useRef();

  // const sportsSlider = useRef();
  const items = useRef();
  const nav = useNavigate();
  const { id } = useParams();
  const itemId = parseInt(id);
  const components = useRef();
  const sliders = useRef();

  const getDataById = (id) => {
    return vehicleCategory.find((entry) => entry.id === id)?.data || [];
  };
  const cartype = getDataById(itemId)?.map((data) => data?.type);


  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      let cargallery = document.querySelector(".feature-card");
      let sectionWrap = document.querySelector(
        ".scrollinnerwrap .feature-inner-blk"
      );

      if (window.screen.width > 567) {
        gsap.to(cargallery, {
          x: () => -(cargallery.scrollWidth - sectionWrap.offsetWidth) + "px",
          ease: "power1. inOut",
          scrollTrigger: {
            trigger: "cargallery",
            pin: ".scrollcontainer",
            pinSpacing: true,
            scrub: 2,
            end: "+=3000",
            markers: false,
          },
        });
      }

    }, collectionComponent);
    return () => ctx.revert();
  });

  const collectionHandleDetail = (datas) => {
    nav("/car-detail");
    localStorage.setItem("myState", JSON.stringify(datas));
  };

  const sedanHandelDetail = (data) => {
    nav("/car-detail");
    localStorage.setItem("myState", JSON.stringify(data));
  };


  const coupeHandelDetail = (datas) => {
    nav("/car-detail");
    localStorage.setItem("myState", JSON.stringify(datas));
  };


  const suvHandleDetail = (datas) => {

    nav("/car-detail");
    localStorage.setItem("myState", JSON.stringify(datas));
  };


  const convertableHandleDetail = (datas) => {

    nav("/car-detail");
    localStorage.setItem("myState", JSON.stringify(datas));
  };



  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
    nav("/ourcollection")
    window.scrollTo(0, 0);
  };


  const sedanCars = vehicleCategory.filter((data) => data.data.find((car) => car.type === "Sedan"));
  const coupeCars = vehicleCategory.filter((data) => data.data.find((car) => car.type === "coupe"));
  const convertableCars = vehicleCategory.filter((data) => data.data.find((car) => car.type === "Convertible"));
  const suvCars = vehicleCategory.filter((data) => data.data.find((car) => car.type === "Suv"));

  useEffect(() => {
    // Disable scroll restoration when the component mounts
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    // Re-enable scroll restoration when the component unmounts
    return () => {
      if ("scrollRestoration" in window.history) {
        window.history.scrollRestoration = "auto";
      }
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div>
      <Header />
      <div className="selected_cars">
        <select value={selectedOption} onChange={handleSelectChange}>
          <option disabled value="">
            Select Car Type
          </option>
          <option value="sedan">Sedan</option>
          <option value="coupe">Coupe</option>
          <option value="convertible">Convertible</option>
          <option value="suv">Suv</option>
        </select>
      </div>
      {selectedOption == "" || selectedOption == "Our Collection" ? (
        <CarCollections
          ref={collectionComponent}
          sliderRef={collectionSlider}
          cursorChangeHandler={cursorChangeHandler}
          id={id}
          cartype={cartype}
          vehicleCategory={vehicleCategory}
          collectionHandledetail={collectionHandleDetail}
          items={items.current}
          getDataById={getDataById}
          itemId={itemId}
          handeldetail={collectionHandleDetail}
          brandName="Collection"
          carsArray={vehicleCategory}
        />
      ) : (
        <div ref={collectionComponent}></div>
      )}

      {selectedOption == "sedan" ?
        <CarCollections
          ref={component}
          sliderRef={slider}
          cursorChangeHandler={cursorChangeHandler}
          id={id}
          cartype={cartype}
          vehicleCategory={vehicleCategory}
          collectionHandledetail={sedanHandelDetail}
          items={items.current}
          getDataById={getDataById}
          itemId={itemId}
          handeldetail={sedanHandelDetail}
          brandName="Sedan"
          carsArray={sedanCars}
        />
        : (<div ref={component}></div>)}

      {selectedOption == "coupe"
        ? <CarCollections
          ref={components}
          sliderRef={sliders}
          cursorChangeHandler={cursorChangeHandler}
          id={id}
          cartype={cartype}
          vehicleCategory={vehicleCategory}
          collectionHandledetail={coupeHandelDetail}
          items={items.current}
          getDataById={getDataById}
          itemId={itemId}
          handeldetail={coupeHandelDetail}
          brandName="Coupe"
          carsArray={coupeCars}
        />
        : (<div className="jk" ref={components}></div>)}

      {selectedOption == "suv"
        ? <CarCollections
          ref={suvComponent}
          sliderRef={suvSliders}
          cursorChangeHandler={cursorChangeHandler}
          id={id}
          cartype={cartype}
          vehicleCategory={vehicleCategory}
          collectionHandledetail={suvHandleDetail}
          items={items.current}
          getDataById={getDataById}
          itemId={itemId}
          handeldetail={suvHandleDetail}
          brandName="Suv"
          carsArray={suvCars}
        /> : (<div ref={suvComponent}></div>)}

      {selectedOption == "convertible"
        ? <CarCollections
          ref={convertibleComponent}
          sliderRef={convertableSlider}
          cursorChangeHandler={cursorChangeHandler}
          id={id}
          cartype={cartype}
          vehicleCategory={vehicleCategory}
          collectionHandledetail={convertableHandleDetail}
          items={items.current}
          getDataById={getDataById}
          itemId={itemId}
          handeldetail={convertableHandleDetail}
          brandName="Convertible"
          carsArray={convertableCars}
        /> : (
          <div ref={convertibleComponent}></div>
        )}
    </div>
  );
};
export default OurCollection;