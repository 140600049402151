import { HashRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import "./components/custom_mouse/dotring.css";
import Features from "./components/features";
import AboutUs from "./components/aboutUs";
import ContactUs from "./components/contactUs";
import Homepage from "./components/homepage";
import CursorFollow from "./components/custom_mouse/cursor";
import WeddingCar from "./components/weddingCar";
import Rentals from "./components/rentals";
import Membership from "./components/supercarTour";
import CorporatePackages from "./components/corporatePackages";
import VideoShoots from "./components/videoShoots";
import CarDetail from "./components/carDetail";
import { useEffect } from "react";
import Discover from "./components/membership";
import OurCollection from "./components/ourCollections";
import NewServices from "./components/newservices";
import $ from "jquery";

function App() {
  useEffect(() => {
    $(document).ready(function () {
      $(window).scroll(function () {
        var a = $(window).scrollTop(); // Corrected the line here
        if (a >= 100) {
          $("header").addClass("bg-header");
        } else {
          $("header").removeClass("bg-header");
        }
      });
    });
  }, []);
  return (
    <HashRouter>
      <CursorFollow />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/featured-cars" element={<Features />} />
        <Route path="/ourcollection/:id" element={<OurCollection />} />
        <Route path="/ourcollection" element={<OurCollection />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/weddingcar" element={<WeddingCar />} />
        <Route path="/rentals" element={<Rentals />} />
        <Route path="/supercartour" element={<Membership />} />
        <Route path="/corporatepackage" element={<CorporatePackages />} />
        <Route path="/videoshoots" element={<VideoShoots />} />
        <Route path="/car-detail" element={<CarDetail />} />
        <Route path="/membership" element={<Discover />} />
        <Route path="/newservices" element={<NewServices />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
