import React, { useEffect, useState } from "react";

const CustomCursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isHovered, setIsHovered] = useState(false);

  // Update cursor position on mouse move
  const onMouseMove = (e) => {
    setPosition({ x: e.clientX, y: e.clientY });
  };

  // Handle mouse enter and leave events to show/hide follower circle
  const onMouseEnter = () => {
    setIsHovered(true);
  };

  const onMouseLeave = () => {
    setIsHovered(false);
  };
  useEffect(()=>{
    const onMouseEnter = () => {
      setIsHovered(true);
    };
  
    const onMouseLeave = () => {
      setIsHovered(false);
    };
  },[])

  useEffect(() => {
    window.addEventListener("mousemove", onMouseMove);
    return () => {
      window.removeEventListener("mousemove", onMouseMove);
    };
  }, []);

  return (
    <>
      <div
        className={`custom-cursor ${isHovered ? "hovered" : ""}`}
        style={{
          left: position.x - 0,
          top: position.y - 0,
        }}
      />

      <div
        className="follower"
        style={{
          left: position.x - 0,
          top: position.y - 0,
        }}
      />

      {/* <div
        className="custom-cursor-tracker"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      /> */}
    </>
  );
};

export default CustomCursor;
