// import gsap from "gsap";
// import React, { useContext,  useEffect,  useLayoutEffect, useRef, useState } from "react";
// import Header from "./header";
// import { MouseContext } from "./custom_mouse/context/mouse-context";
// import { useNavigate, useParams } from "react-router-dom";
// import vehicleCategory from ".././services/JSON/vehicleCategory.json";
// import { ScrollTrigger } from "gsap/all";
// import CarCollections from "./carCollections";

const CarCollections = ({
  ref,
  sliderRef,
  cursorChangeHandler,
  id,
  cartype,
  collectionHandledetail,
  items,
  getDataById,
  itemId,
  handeldetail,
  brandName,
  carsArray
}) => {
  return (
    <div>
      <div className="scrollcontainer" ref={ref}>
        <div className="scrollinnerwrap">
          <section className="collection-section ">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="feature-blk">
                    <div className="feature-inner-blk">
                      <div className="feature-card" ref={sliderRef}>
                        <div className="feature-inner-card feature-heading-blk">
                          <h2
                            className="feature-heading"
                            onMouseEnter={() => cursorChangeHandler("hovered")}
                            onMouseLeave={() => cursorChangeHandler("")}>
                            {(id === undefined)
                              ? brandName
                              : cartype.slice(0, 1)}
                          </h2>
                        </div>
                        {/* All cars details */}
                        {id === undefined
                          ? carsArray?.map((data) => data?.data?.map((data, index) => {
                            return (
                              <div
                                onClick={() => collectionHandledetail(data)}
                                className="feature-inner-card"
                                key={index}
                                ref={items}>
                                <div className="feature-cars-img">
                                  <img src={data?.carImage} alt="cars" />
                                </div>
                                <h2 className="car-name">{data?.carName}</h2>
                                <span className="car-tagline">
                                  {data?.carTagline}
                                </span>
                                <span className="car-tagline">
                                  {data?.type}
                                </span>
                                <span className="car-rent">
                                  {data?.carRent}
                                </span>
                                <div className="car-detail-blk">
                                  <ul className="car-detail">
                                    {data.carDetails.map(
                                      (detail, detailIndex) => (
                                        <li key={detailIndex}>
                                          <span className="car-detail-icon">
                                            <img
                                              src={detail.icon}
                                              alt={detail.text}
                                            />
                                          </span>
                                          <span className="car-detail-text">
                                            {detail.text}
                                          </span>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            );
                          }))
                          : getDataById(itemId).map((data, index) => {
                            console.log(data);
                            return (
                              <div
                                className="feature-inner-card"
                                key={index}
                                onClick={() => handeldetail(data)}>
                                <div className="feature-cars-img">
                                  <img src={data?.carImage} alt="cars" />
                                </div>
                                <h2 className="car-name">{data?.carName}</h2>
                                <span className="car-tagline">
                                  {data?.type}
                                </span>
                                <span className="car-rent">
                                  {data?.carRent}
                                </span>
                                <div className="car-detail-blk">
                                  <ul className="car-detail">
                                    {data.carDetails.map(
                                      (detail, detailIndex) => (
                                        <li key={detailIndex}>
                                          <span className="car-detail-icon">
                                            <img
                                              src={detail.icon}
                                              alt={detail.text}
                                            />
                                          </span>
                                          <span className="car-detail-text">
                                            {detail.text}
                                          </span>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg">
              <video loop autoPlay muted playsInline>
                <source src="videos/cullinan.mp4" type="video/mp4" />
              </video>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default CarCollections