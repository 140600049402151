import React, { useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import { useNavigate } from "react-router-dom";

function NewServices() {
  const nav = useNavigate();

  useEffect(() => {
    // Disable scroll restoration when the component mounts
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    // Re-enable scroll restoration when the component unmounts
    return () => {
      if ("scrollRestoration" in window.history) {
        window.history.scrollRestoration = "auto";
      }
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header />
      <div class="mainwrap">
        <section
          className="services-hero"
          style={{ position: "relative", overflow: "hidden" }}>
          <div className="bg2">
            <video
              autoPlay
              muted
              loop
              playsInline
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                zIndex: "-1",
                objectFit: "cover", // This ensures video covers the section
                background: "rgb(0 0 0 / 45%)", // Keeping the dark overlay
              }}>
              <source src="/videos/cullinan.mp4" type="video/mp4" />
            </video>
          </div>

          <div className="service-hero-inner-blk">
            <h1>Feel Like a King While Getting To Your Destination.</h1>
            <h2>Luxury Car Service</h2>
          </div>
          <div className="effect">
            <h2 className="splittext">SCROLL TO EXPLORE</h2>
          </div>
          <div className="scroll-down">
            <span className="rectline" />
            <span className="rectblk" />
          </div>
        </section>

        <section class="service-content-section">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-12">
                <div class="content-blk">
                  <h3>Where Prestige Meets Passion.</h3>
                  <p>
                    Broadway Supercars is not just a name, but an experience—a
                    symphony of elegance, power, and prestige. Like the
                    craftsmanship behind a Rolls Royce or the refined luxury of
                    a Bentley, our membership transcends the ordinary, offering
                    discerning individuals a serene passage into the world of
                    grandeur.
                  </p>
                  <p>
                    Our collection is curated with the utmost precision,
                    presenting an array of automobiles that whisper
                    sophistication. Every drive isn't just a journey; it's a
                    serene escape—a gentle embrace of luxury that complements
                    the world's most iconic roads.
                  </p>
                  <br></br>
                  <h3>Timeless Value.</h3>
                  <p>
                    We recognize that true luxury is timeless, unfazed by the
                    ephemeral trends of the world. Our membership stands as a
                    reflection of this belief, offering continuous benefits that
                    enrich every interaction. Each rental, every drive, is
                    accompanied by a generous 20% concession—an enduring
                    testament to our commitment to your exceptional taste.
                  </p>
                  <p>
                    {" "}
                    Just as the world's finest timepieces and heirlooms accrue
                    value with every passing second, your association with us
                    deepens, offering an evergreen blend of privilege and
                    prestige. This isn't a fleeting offer, but a steadfast
                    promise—a value that remains unwavering in its elegance and
                    exclusivity. With Broadway Supercars, every moment holds a
                    promise of enduring grandeur, making your experiences truly
                    priceless.
                  </p>
                  <button class="contact-btn" onClick={() => nav("/contact")}>
                    Contact Us
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section class="img-card-section"> */}

        <section class="img-card-section">
          {/* <div class="images-blk">
             <div class="first-img-blk">
              <img
                src="https://images.hdqwalls.com/wallpapers/2023-rolls-royce-cullinan-black-badge-blue-shadow-8k-gi.jpg"
                alt="Car"
              />
            </div> 
            
             <div class="third-img-blk">
              <img
                src="https://images8.alphacoders.com/123/1234829.jpg"
                alt="Car"
              />
            </div> 
          </div> */}
          <div class="full-img-blk">
            <img
              src="https://www.hdcarwallpapers.com/walls/mercedes_maybach_gls_600_4matic_2020_4k-HD.jpg"
              alt="Car"
            />
          </div>
        </section>

        {/* </section> */}

        <section class="service-slider-section">
          <div class="container">
            <div class="row justify-content-center content-blk gx-0">
              <div class="col-lg-12">
                <div class="content-blk">
                  {/* <h2>COACHBUILD</h2> */}
                  <h3>The Pinnacle of Bespoke.</h3>
                  <p>
                    While the allure of our vehicles is open to many, membership
                    with us offers an elevation in this experience. As a
                    privileged member, your connection with our exotic fleet
                    goes beyond the ordinary. Nestling into the plush seats, you
                    don’t just feel leather and craftsmanship; you feel the
                    added layer of prestige that comes with belonging to an
                    elite circle. Initiating the ignition isn’t merely starting
                    a car; it's igniting a relationship of deeper resonance and
                    affinity with the machine. The powerful symphony of the
                    engine, the exhilarating pulse of acceleration, they all
                    sing a little louder, a little clearer, for those who have
                    chosen to be part of our distinguished membership. It’s not
                    just about driving; it's about experiencing vehicular luxury
                    at its most profound level, reserved especially for you.
                  </p>
                  <p>
                    Membership grants you more than mere access—it grants you a
                    journey bathed in admiration. As you navigate cityscapes or
                    countryside, the gleam of your chosen exotic car captures
                    the essence of luxury, turning heads and drawing reverent
                    gazes. It's a silent acknowledgment of your place among the
                    select few, those with the discernment to appreciate life’s
                    finer moments. And when you gracefully alight from these
                    mechanical marvels at your chosen destinations, the world
                    doesn't just see a driver; it witnesses the arrival of
                    someone who embodies exclusivity, all thanks to the elevated
                    experience your Broadway Supercars membership promises.
                  </p>
                  <a href="#" class="section-btn">
                    Explore
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="slider-wrapper">
            <div class="container-fluid g-0">
              <div class="row gx-0">
                <div class="col-lg-12">
                  <div class="slider-blk">
                    <div class="img-slide">
                      <img
                        src="https://www.hdcarwallpapers.com/walls/range_rover_sv_intrepid_2022_4k_8k-HD.jpg"
                        alt="Car"
                      />
                    </div>
                    <div class="car-text-block row justify-content-center col-lg-6 content-blk">
                      <h3>An Aura of Distinction.</h3>
                      <p>
                        {" "}
                        Membership with us isn't just about the drive; it's an
                        immersion into an aura of distinction. Each vehicle in
                        our curated collection is more than a machine; it's a
                        manifestation of art, history, and innovation. As you
                        navigate through winding roads or urban landscapes, you
                        don't just experience speed and agility; you live a
                        narrative of luxury's evolution. The world perceives not
                        just a driver, but an individual who appreciates the
                        finer nuances of life, someone who, with every journey,
                        paints the town with strokes of opulence and class.
                        Every drive is an unspoken invitation to a world where
                        luxury isn’t just owned; it's lived and felt profoundly.
                      </p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                        class="slider-button-blk">
                        <a href="#/ourcollection" class="slider-btn">
                          Collection
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <br></br> */}
            {/* <br></br> */}
            {/* <br></br> */}
            {/* <div class="container">
              <div class="row justify-content-center topmargin gx-0">
                <div class="col-sm-6 col-lg-4">
                  <div class="common-card">
                    <div class="common-inner-wrap">
                      <div class="common-card-header-blk price-format-header">
                        <h2
                          style={{ textAlign: "center" }}
                          class="price-format">
                          VIP Membership
                        </h2>
                      </div>
                      <div class="common-card-header-blk price-header">
                        <h2
                          style={{ textAlign: "center" }}
                          class="price-amount">
                          <span class="amount-currency">$</span>500
                          <span class="amount-period">/ month</span>
                        </h2>
                      </div>
                      <div class="common-form-details feature-details">
                        <form>
                          <div class="checkbox-blk">
                            <div class="check-input-blk">
                              <input
                                type="checkbox"
                                id="freeFeature1"
                                checked
                              />
                              <label for="freeFeature1"></label>
                              <span>20% Off Every Rental</span>
                            </div>
                          </div>
                          <div class="checkbox-blk">
                            <div class="check-input-blk">
                              <input
                                type="checkbox"
                                id="freeFeature2"
                                checked
                              />
                              <label for="freeFeature2"></label>
                              <span>Complimentary Rental on Birthday</span>
                            </div>
                          </div>
                          <div class="checkbox-blk">
                            <div class="check-input-blk">
                              <input
                                type="checkbox"
                                id="freeFeature3"
                                checked
                              />
                              <label for="freeFeature3"></label>
                              <span>30% Off Tours and Packages</span>
                            </div>
                          </div>
                          <div class="checkbox-blk">
                            <div class="check-input-blk">
                              <input
                                type="checkbox"
                                id="freeFeature5"
                                checked
                              />
                              <label for="freeFeature5"></label>
                              <span>Free Drop Off and After-Hours Pickup</span>
                            </div>
                          </div>
                          <div class="checkbox-blk">
                            <div class="check-input-blk">
                              <input
                                type="checkbox"
                                id="freeFeature6"
                                checked
                              />
                              <label for="freeFeature6"></label>
                              <span>Loyalty Program</span>
                            </div>
                          </div>
                          <div class="checkbox-blk">
                            <div class="check-input-blk">
                              <input
                                type="checkbox"
                                id="freeFeature7"
                                checked
                              />
                              <label for="freeFeature7"></label>
                              <span>Free Merchandise</span>
                            </div>
                          </div>
                          <div class="checkbox-blk">
                            <div class="check-input-blk">
                              <input
                                type="checkbox"
                                id="freeFeature8"
                                checked
                              />
                              <label for="freeFeature8"></label>
                              <span>Lower Security Deposit</span>
                            </div>
                          </div>
                          <div class="checkbox-blk">
                            <div class="check-input-blk">
                              <input
                                type="checkbox"
                                id="freeFeature12"
                                checked
                              />
                              <label for="freeFeature12"></label>
                              <span>Priority Booking</span>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div class="buy-plan-blk">
                        <a
                          href="https://buy.stripe.com/8wMcMZ3Pr9ZC9GMeUU"
                          target="_blank"
                          class="buyplan">
                          Buy Plan
                        </a>
                      </div> */}
            {/* </div> */}
            {/* </div> */}
            {/* </div> */}
            {/* </div> */}
            {/* </div> */}
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
export default NewServices;
