// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ring {
  position: fixed;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  background-color: rgba(31, 30, 30, 0.808);
  /* border: 2px solid rgba(31, 30, 30, 0.808); */
  border-radius: 100%;
  transform: translate(-50%, -50%);
  transition-duration: 100ms;
  transition-timing-function: ease-out;
  will-change: width, height, transform, border;
  z-index: 999;
  pointer-events: none;
}

.dot {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  background-color: #004bfa;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  z-index: 999;
  pointer-events: none;
}

.ring.hovered {
  width: 50px;
  height: 50px;
  border-width: 3px;
  background-color: lightgray;
  /* border-color: lightgray; */
}

.dot.hovered {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/custom_mouse/dotring.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,yCAAyC;EACzC,+CAA+C;EAC/C,mBAAmB;EACnB,gCAAgC;EAEhC,0BAA0B;EAE1B,oCAAoC;EACpC,6CAA6C;EAC7C,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,QAAQ;EACR,SAAS;EACT,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,mBAAmB;EACnB,gCAAgC;EAChC,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,2BAA2B;EAC3B,6BAA6B;AAC/B;;AAEA;EACE,aAAa;AACf","sourcesContent":[".ring {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 40px;\n  height: 40px;\n  background-color: rgba(31, 30, 30, 0.808);\n  /* border: 2px solid rgba(31, 30, 30, 0.808); */\n  border-radius: 100%;\n  transform: translate(-50%, -50%);\n  -webkit-transition-duration: 100ms;\n  transition-duration: 100ms;\n  -webkit-transition-timing-function: ease-out;\n  transition-timing-function: ease-out;\n  will-change: width, height, transform, border;\n  z-index: 999;\n  pointer-events: none;\n}\n\n.dot {\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  width: 18px;\n  height: 18px;\n  background-color: #004bfa;\n  border-radius: 100%;\n  transform: translate(-50%, -50%);\n  z-index: 999;\n  pointer-events: none;\n}\n\n.ring.hovered {\n  width: 50px;\n  height: 50px;\n  border-width: 3px;\n  background-color: lightgray;\n  /* border-color: lightgray; */\n}\n\n.dot.hovered {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
