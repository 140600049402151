/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useLayoutEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import gsap from "gsap";
import navigationPath from ".././services/JSON/navigationPath.json";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [servicesOpen, setServicesOpen] = useState(false);
  const nav = useNavigate();

  function openMenuBar() {
    setMenuOpen(!menuOpen);
  }

  const handleServicesOpen = () => {
    setServicesOpen(!servicesOpen);
  };

  useEffect(() => {
    // Disable scroll restoration when the component mounts
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    // Re-enable scroll restoration when the component unmounts
    return () => {
      if ("scrollRestoration" in window.history) {
        window.history.scrollRestoration = "auto";
      }
    };
  }, []);

  useLayoutEffect(() => {
    //   const ham = document.querySelector(".menu-icon");
    //   const menu = document.querySelector(".menu-wrapper");
    //   const links = menu.querySelectorAll(".menu-wrapper ul li.layer-link");
    //   const activelinks = document.querySelector("a.page-links2");
    //   var tl2 = gsap.timeline();
    //     tl2.to(menu, {
    //       duration: 0.2,
    //       opacity: 1,
    //       width: '44%',
    //       ease: 'expo.inOut'
    //     }, "-=0.2")
    //     tl2.from(links, {
    //       duration: 1,
    //       opacity: 0,
    //       x: -300,
    //       stagger: 0.1,
    //       ease: 'expo.inOut'
    //     }, "+=0.2");
    //     ham.addEventListener('click', () => {
    //       tl2.reversed(!tl2.reversed());
    //     });
    //     activelinks?.addEventListener('click', () => {
    //       tl2.reversed(!tl2.reversed());
    //     });
    //     tl2.reverse();

    let breakPoint = 992;

    let mm = gsap.matchMedia();
    const ham = document.querySelector(".menu-icon");
    const menu = document.querySelector(".menu-wrapper");
    const links = menu.querySelectorAll(".menu-inner-wrapper ul li.layer-link");
    const activelinks = document.querySelector("a.page-links2");

    mm.add(
      {
        isDesktop: `(min-width: ${breakPoint}px)`,
        isMobile: `(max-width: ${breakPoint - 1}px)`,
        reduceMotion: "(prefers-reduced-motion: reduce)",
      },
      (context) => {
        let { isDesktop, isMobile, reduceMotion } = context.conditions;

        var tl2 = gsap.timeline();

        tl2.to(
          menu,
          {
            duration: 0.2,
            opacity: 1,
            width: isDesktop ? "44%" : "100%",
            ease: "expo.inOut",
          },
          "+=0.2"
        );
        tl2.from(
          links,
          {
            duration: 1,
            opacity: 0,
            x: -300,
            stagger: 0.1,
            ease: "expo.inOut",
          },
          "+=0.2"
        );

        tl2.reverse();

        ham.addEventListener("click", () => {
          tl2.reversed(!tl2.reversed());
        });
        activelinks?.addEventListener("click", () => {
          tl2.reversed(!tl2.reversed());
        });

        return () => console.log("cleanup");
      }
    );
  }, []);

  return (
    <div>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="header-wrapper">
                <div className="menu-btn-blk" onClick={() => openMenuBar()}>
                  <button
                    className={`menu-icon ${menuOpen && "open"}`}
                    style={{ cursor: "none" }}>
                    <span className="icon-left" />
                    <span className="icon-right" />
                  </button>
                </div>
                <div className="logo-blk">
                  <NavLink to="/">
                    <img src="images/logo.png" alt="Logo" />
                  </NavLink>
                </div>
                <div class="book-btn-blk">
                  <a
                    href="https://fareharbor.com/embeds/book/broadwaysupercars/?full-items=yes"
                    target="_blank"
                    rel="noopener noreferrer">
                    Book Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`menu-wrapper`}>
          <div className="menu-inner-wrapper">
            <ul>
              {navigationPath.map((data, i) => (
                <li className="layer-link" key={data.path}>
                  <a
                    className={
                      data.path == `/${window.location.hash.split("/")[1]}`
                        ? "page-links2"
                        : "page-links"
                    }
                    id={data.name === "SERVICES" && "servicelist"}
                    onClick={() => {
                      data.name === "SERVICES"
                        ? handleServicesOpen()
                        : nav(data.path);
                    }}>
                    {data.name}
                  </a>
                  {data.name === "SERVICES" ? (
                    <div
                      class={
                        servicesOpen
                          ? "service-nav-list showlist"
                          : "service-nav-list"
                      }>
                      <ul>
                        {data.array.map((data, i) => (
                          <li key={data.path}>
                            <a
                              className="service-link"
                              onClick={() => {
                                nav(data.path);
                                window.scrollTo(0, 0);
                              }}>
                              {data.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : null}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-is-valid */
