import React, { useContext, useLayoutEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import features2 from "../services/JSON/features2.json";
import Header from "./header";
import { MouseContext } from "./custom_mouse/context/mouse-context";
import { useNavigate } from "react-router-dom";
gsap.registerPlugin(ScrollTrigger);
const Features = () => {
  // Mouse handler
  const { cursorType, cursorChangeHandler } = useContext(MouseContext);

  // Feature section ref for animation

  const components = useRef();
  const sliders = useRef();
  const nav = useNavigate()
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      // let features2 = gsap.utils.toArray(".feature-section1 .feature-inner-card");

      gsap.to(features2, {
        xPercent: -40 * (features2.length - 1),
        ease: "none",
        scrollTrigger: {
          trigger: ".inner-wrap-3",
          pin: ".main-3",
          pinSpacing: true,
          scrub: 1,
          end: "+=3000",
          markers: false,
        },
      });
      let features2 = gsap.utils.toArray(".vehicle-section .feature-inner-card");

      gsap.to(features2, {
        xPercent: -50 * (features2.length - 1),
        ease: "none",
        scrollTrigger: {
          trigger: ".vehicle-wrap",
          pin: ".main-4",
          pinSpacing: true,
          scrub: 1,
          end: "+=3000",
          markers:true
        }
      });
      // sliders.current = gsap
      //   .timeline()
      //   .to(".feature-inner-card", { x: 300, delay: 1 });
    }, components);
    return () => ctx.revert();
  });
  const handeldetails = (datas) => {
    nav("/car-detail")
    localStorage.setItem("myState", JSON.stringify(datas))
  }

  return (
    <div>
      <div className="height">
      <div className="main-4" ref={components}>
        <div className="vehicle-wrap">
          <section className="vehicle-section ">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="feature-blk">
                    <div className="feature-inner-blk">
                      <div className="feature-card" ref={sliders}>
                        <div className="feature-inner-card feature-heading-blk">
                          <h2
                            className="feature-heading"
                            onMouseEnter={() => cursorChangeHandler("hovered")}
                            onMouseLeave={() => cursorChangeHandler("")}>
                            featured cars
                          </h2>
                        </div>
                        {features2.slice(4, 9)?.map((datas, index) => {
                          return (
                            <div className="feature-inner-card" key={index} onClick={() => handeldetails(datas)}>
                              <div className="feature-cars-img">
                                <img src={datas?.carImage} alt="cars" />
                              </div>
                              <h2 className="car-name">{datas?.carName}</h2>
                              <span className="car-tagline">
                                {datas?.carTagline}
                              </span>
                              <span className="car-rent">{datas?.carRent}</span>
                              <div className="car-detail-blk">
                                <ul className="car-detail">
                                  {datas.carDetails.map(
                                    (detail, detailIndex) => (
                                      <li key={detailIndex}>
                                        <span className="car-detail-icon">
                                          <img
                                            src={detail.icon}
                                            alt={detail.text}
                                          />
                                        </span>
                                        <span className="car-detail-text">
                                          {detail.text}
                                        </span>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg">
              <video loop autoPlay muted playsInline  >
                <source src="videos/broadway-cars.mp4" type="video/mp4" />
              </video>
            </div>
          </section>
        </div>
      </div>
      </div>
      
    </div>
  );
};

export default Features;
