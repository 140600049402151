import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import userdetail from "../services/JSON/userdetail.json";
import Header from "./header";
import gsap, { Power3 } from "gsap";
import { initialValues, validationSchema } from "../validations";
import { useFormik } from "formik";
import MapComponent from "./mapComponent/mapComponent";
import Footer from "./footer";

const ContactUs = () => {
  // State to control animation
  const [isAnimationActive, setIsAnimationActive] = useState(true);
  // animation feature

  function FadeIn({ children, vars }) {
    const el = useRef();

    useLayoutEffect(() => {
      if (isAnimationActive) {
        const ctx = gsap.context(() => {
          Animation.current = gsap.from(el.current.children, {
            opacity: 0,
            autoAlpha: 0,
            duration: 0.75,
            ...vars,
          });
        });
        return () => ctx.revert();
      }
    }, [isAnimationActive]);

    return <div ref={el}>{children}</div>;
  }
  // Input validation
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      alert("Send message successfully");
      // alert(values);
      resetForm();
    },
  });
  useEffect(() => {
    // Disable scroll restoration when the component mounts
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    // Re-enable scroll restoration when the component unmounts
    return () => {
      if ("scrollRestoration" in window.history) {
        window.history.scrollRestoration = "auto";
      }
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header />
      <div class="mainwrap">
        <section className="contact-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-11">
                <div className="contact-blk common-blk">
                  <div className="contact-inner-blk common-inner-blk">
                    <div className="contact-heading common-heading">
                      <h1>Contact us</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="scroll-down">
            <span className="rectline" />
            <span className="rectblk" />
          </div>
        </section>

        <section className="common-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="contact-blk common-blk">
                  <div className="contact-inner-blk common-inner-blk">
                    <div className="contact-heading common-heading">
                      {/* animation */}
                      <FadeIn vars={{ y: 100, ease: Power3.out, stagger: 1.5 }}>
                        <h1>Contact us</h1>
                      </FadeIn>
                    </div>
                    <div className="contact-content common-content">
                      <div className="contact-detail-wrapper">
                        {/* Details for user */}
                        {userdetail.map((data) => (
                          <div className="contact-detail-blk">
                            <FadeIn
                              vars={{
                                x: 100,
                                ease: "elastic.out(1, 1)",
                                stagger: {
                                  each: 0.75,
                                  amount: 0.5,
                                },
                              }}>
                              <p>Address: {data.address}</p>
                              <p>
                                Phone:{" "}
                                <a href="tel:+19737798405">(973) 779-8405</a>
                              </p>
                              <p>
                                Email:{" "}
                                <a href="mailto:sales@broadwaysupercars.com">
                                  {data.email}
                                </a>
                              </p>
                              <h2>Stay In Touch With Us</h2>
                            </FadeIn>
                          </div>
                        ))}
                        <div className="contact-form-blk">
                          <div className="input-field">
                            <input
                              type="email"
                              placeholder="Email"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onFocus={() => setIsAnimationActive(false)}
                            />
                            {touched.email && errors.email && (
                              <p style={{ color: "red" }}>{errors.email}</p>
                            )}
                          </div>
                          <div className="input-field">
                            <input
                              type="text"
                              placeholder="Title"
                              name="title"
                              value={values.title}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onFocus={() => setIsAnimationActive(false)}
                            />
                            {touched.title && errors.title && (
                              <p style={{ color: "red" }}>{errors.title}</p>
                            )}
                          </div>
                          <div className="input-field">
                            <input
                              type="text"
                              placeholder="Your message"
                              name="message"
                              value={values.message}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onFocus={() => setIsAnimationActive(false)}
                            />
                            {touched.message && errors.message && (
                              <p style={{ color: "red" }}>{errors.message}</p>
                            )}
                          </div>

                          <div className="input-field">
                            <input
                              placeholder="Enter Mobile Number"
                              name="mobileNumber"
                              value={values.mobileNumber}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onFocus={() => setIsAnimationActive(false)}
                            />
                            {touched.mobileNumber && errors.mobileNumber && (
                              <p style={{ color: "red" }}>
                                {errors.mobileNumber}
                              </p>
                            )}
                          </div>

                          <div className="input-btn-blk">
                            <button
                              style={{ cursor: "none" }}
                              className="send-message-btn"
                              type="button"
                              onClick={() => handleSubmit()}>
                              Send Message
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="location-blk">
                        <div className="map">
                          <MapComponent />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="service-content-section about-content-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="content-blk work-with">
                  <h2>Work with us</h2>
                  <p>
                    Looking for a career in the exotic car market? Have an
                    exotic vehicle that you'd like to rideshare and make more
                    money than is possible with Turo? Looking to be an affiliate
                    marketer with a customer referral link to get some sales
                    commission?
                  </p>
                  <p>Whatever the case, reach out and lets connect.</p>
                  <a href="#" className="section-btn">
                    Contact
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>

      <Footer />
    </div>
  );
};

export default ContactUs;
